<template>
  <modal name="communicator-create-modal" @closed="closeModal()" :height="'auto'" :classes="'bg-primary-three'">
    <div class="p-10">
      <div class="flex justify-between pb-5 i-border-b-1 border-primary-one">
        <h3 class="font-poppins text-primary-one fs-26">Create New Communicator </h3>
      </div>

      <div class="i-mt-30">
        <div>
          <h4 class="font-poppins text-secondary-one fs-14">Communicator Title</h4>
          <input type="text"
                 class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-primary-one rounded"
                 v-model="communicator.title"
                 :placeholder="communicatorPlaceholder.title"
                 @click="communicatorPlaceholder.title = ''"
                 v-click-outside="outsideTitleInput" required>
        </div>
        <div class="i-mt-30">
          <h4 class="font-poppins text-secondary-one fs-14">Description</h4>
          <textarea class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 py-2 h-6r i-border-1 border-solid border-primary-one rounded bld-content-area"
                    v-model="communicator.description"
                    :placeholder="communicatorPlaceholder.description"
                    @click="communicatorPlaceholder.description = ''"
                    v-click-outside="outsideDescription"></textarea>
        </div>
<!--        <div class="i-mt-30">-->
<!--          <h4 class="font-poppins text-secondary-one fs-14">Category</h4>-->
<!--          <select class="bg-transparent text-secondary-two fs-14 w-full i-border-1 border-primary-one mt-2 py-2 rounded px-4" v-model="communicator.communicator_category_id">-->
<!--            <option value="" selected="true">Select Category</option>-->
<!--            <option v-for="(category, sIndex) in communicatorCategories" :key="sIndex" :value="category.id">{{ category.title }}</option>-->
<!--          </select>-->
<!--        </div>-->
<!--        <div class="i-mt-30">-->
<!--          <h4 class="font-poppins text-secondary-one fs-14">Roadmap</h4>-->
<!--          <select class="bg-transparent text-secondary-two fs-14 w-full i-border-1 border-primary-one mt-2 py-2 rounded px-4" v-model="communicator.roadmap_id" required>-->
<!--            <option v-for="(roadmap, rIndex) in roadmaps" :key="rIndex" :value="roadmap.id">{{ roadmap.title }}</option>-->
<!--          </select>-->
<!--        </div>-->
        <div class="i-mt-30 flex gap-6 items-center">
            <label class=" flex gap-3 items-center cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.94 6 12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11c1.56.1 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3zM8 13h2.55v3h2.9v-3H16l-4-4z"/></svg>
                <span>File Upload</span>
                <input @input="(e)=>{onChange(e), fileUpload(file)}" hidden type="file" accept="image/x-png,image/jpeg" />
            </label>
            <img class="w-10 h-8 object-cover" :src="previewURL" />
        </div>

        <div class="flex i-mt-30">
          <button class="fs-14 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one" @click="createCommunicator()" :disabled="loading">Create</button>
          <button class="focus:outline-none fs-14 fw-600 font-poppins i-pl-35 i-pr-35 i-border-1 border-secondary-one ml-5 rounded text-primary-one h-10 w-40" @click="closeModal()" :disabled="loading">Cancel</button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script setup>
  import { useFile } from '@/composable/useFile.js'
    const { onChange, previewURL, file } = useFile()
</script>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CommunicatorCreateModal",
  props: ['dModal'],
  created() {
    // this.$store.dispatch("roadmap/getProgrammatics", {
    //   project_id: this.$route.params.id
    // });
  },
  data() {
    return {
      isScenario: false,
      communicator: {
        title: '',
        description: ''
      },
      communicatorPlaceholder: {
        title: 'Type title here',
        description: 'Your description here'
      },
      formData: new FormData()
    }
  },
  computed: {
    ...mapGetters({
      loading: "GET_LOADING_STATE",
      projectModules: "programmatic/projectModules",
      scenarios: 'programmatic/scenarios',
      roadmaps: "roadmap/programmatics",
      communicatorCategories: "communicator/categories",
    })
  },
  watch: {
    dModal: function (val) {
      if(val) {
        this.$modal.show('communicator-create-modal');
      }
    }
  },
  methods: {
    closeModal() {
      this.$modal.hide('communicator-create-modal');
      this.$emit("toggleCreateModal", false);
    },
    outsideTitleInput() {
      this.communicatorPlaceholder.title = 'Type title here';
    },
    outsideDescription() {
      this.communicatorPlaceholder.description = 'Type description here';
    },
    appends(data) {
        for(let key in data){
            this.formData.append(key, data[key])
        }
    },
    fileUpload(file){
        this.communicator.image = file
    },
    createCommunicator() {
      const self = this;
      this.communicator.project_id = self.$route.params.id;
      this.communicator.scratch = !this.isScenario;
      this.communicator.settings = { color: true };
      // let roadmap = this.roadmaps.find(item => item.id === parseInt(this.communicator.roadmap_id));
      // if(roadmap) {
      //   this.communicator.scenario_id = roadmap.scenario_id;
      // } else {
      //   alert('Roadmap is not found');
      //   return;
      // }

      self.$Progress.start();
      // this.communicator.json_data = this.getDefaultJsonData();

      this.appends(this.communicator)
      self.$store.dispatch("communicator/storeCommunicator", this.formData).then(response => {
        this.$Progress.finish();
        if(response && response.data && response.data.status && response.data.data && response.data.data.id) {
          self.closeModal();
          self.communicator = {
            title: '',
            description: '',
            roadmap_id: ''
          };

          self.$router.push({ name: 'communicator', params: {id: self.$route.params.id, communicator_id: response.data.data.id} });
        }
      });
    },
    getDefaultJsonData() {
      return {
        parameters: [{ property_id: null, id: null, definition_id: null }],
        data_layer: { root_type: 'scenario', root_id: null, first_level_id: null, second_level_id: null, third_level_id: null, not_in_list: [] },
        settings: { theme: 'light' },
        definition_style: [
          { range: '0-2', bg: '#AD2B81', definition: 'Very Low' },
          { range: '2-4', bg: '#ABA62B', definition: 'Low' },
          { range: '4-6', bg: '#8D8D8D', definition: 'Medium' },
          { range: '6-8', bg: '#2BAD7E', definition: 'High' },
          { range: '8-10', bg: '#2B80AD', definition: 'Extreme' }
        ]
      };
    }
  }
}
</script>

<style scoped>
.h-6r {
  height: 6rem;
}
.checkbox {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 2px;
}
.custom-border {
  border: 2px solid #8d8d8d;
}
</style>
