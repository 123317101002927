<template>
  <div class="_main_wrapper relative flex">
    <sidebar
      label="Create Communicator"
      :feature="'Communicator'"
      :categories="categories"
      :display-mode="displayMode"
      :items="communicators"
      @toggleCreateModal="toggleCreateModal"
      @toggleDisplay="toggleDisplay"
      :canCreate="canCreate(project.cans)"
    />
    <div class="i-right-panel">
      <div class="ws-heading-box relative flex flex-col pb-8">
        <workspace-menu />
        <h2 class="fs-14 fw-700 font-poppins text-secondary-one absolute page-heading-title">Communicator Management</h2>
        <div class="ws-action-box flex items-center justify-between ws-select-dropdown">
          <div class="flex scenario-action-box">
            <div class="flex items-center gap-3">
              <div class="relative i-border-1 border-secondary-two rounded ws-search-width">
                <input
                    type="text"
                    class="focus:outline-none py-2 font-poppins ws-search-box bg-transparent text-secondary-one placeholder-secondary-two"
                    :placeholder="searchField.placeholder"
                    v-model="searchField.text"
                    @click="searchField.placeholder = ''"
                    v-click-outside="outsideSearchInput"
                />
                <div class="mr-2 flex items-center justify-between absolute inset-y-0 right-0 pointer-events-none pr-1">
                  <img
                      class="w-3 h-3"
                      src="@/assets/images/icons/search_icon.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="scenario-content-box pl-10 mr-8 mt-8 overflow-y-auto ws-scrollbar">

        <div class="communicatorCards grid grid-cols-3 gap-y-8">
          <DataCard
            v-for="(item, index) in getDisplayData"
            :key="index+Math.floor(Math.random()*1000)"
            :routeObj="{ name: 'communicator', params: { id: $route.params.id, communicator_id: item.id }}"
            :title="item.title"
            subTitle="Active Communicator: "
            img="/img/default_dashboard.svg"
            :category="`Category: ${item.category && item.category.title ? item.category.title : 'N/A'}`"
            :afterSwitch="item.roadmap && item.roadmap.title ? item.roadmap.title : ''"
            :data="[
              {Created: $lodash.format(item.created_at, 'dd/MM/yyyy')},
              {Last_Edited: $lodash.format(item.updated_at, 'dd/MM/yyyy')},
            ]"
            :editCallBack="() => { toggleEditModal(true, item) }"
            :duplicateCallBack="() => { duplicateCommunicator(item) }"
            :deleteCallBack="() => { deleteCommunicator(item.id, index) }"
            :defaultSetCallBack="() => { setDefault(item) }"
            :color="(item.settings && item.settings.color) ? item.settings.color : defaultColor"
            :isDefault="item.is_default"
            :image="item?.file?.file_path"
          />
        </div>
      </div>
    </div>

    <communicator-create-modal :dModal="cModal"
                               :categories="categories"
                               @toggleCreateModal="toggleCreateModal" />
    <communicator-edit-modal :dModal="eModal"
                             :categories="categories"
                             :roadmap="editableData"
                             :ref="'communicator_edit'"
                             @toggleEditModal="toggleEditModal" />
  </div>
</template>

<script setup>
  import DataCard from '@/elements/DataCard.vue'
  import usePermission from '@/composable/usePermission.js'
  const { canCreate } = usePermission()
</script>

<script>
import axios from 'axios'
import Sidebar from "../../../components/roadmap/management/Sidebar";
import CommunicatorCreateModal from "../../../components/communicator/CreateModal";
import CommunicatorEditModal from "../../../components/communicator/EditModal";
import {mapGetters} from "vuex";

export default {
  name: "Index",
  components: {CommunicatorEditModal, CommunicatorCreateModal, Sidebar},
  created() {
    document.title = "Communicator Management";
    const self = this;
    this.$store.dispatch("communicator/getCategories");
    self.$store.dispatch("workspace/projectSelection", self.$route.params.id);
    this.$store.dispatch("project/getSolidObject", this.$route.params.id);
    self.$Progress.finish();
    self.$store.dispatch("communicator/getCommunicators", {
      project_id: self.$route.params.id
    }).then(response => {
      self.$Progress.finish();
    });

    // this.$store.dispatch("roadmap/getProgrammatics", {
    //   project_id: this.$route.params.id
    // });
  },
  data() {
    return {
      cModal: false,
      eModal: false,
      displayMode: 'all',
      searchField: {
        placeholder: 'Search',
        text: ''
      },
      hoveredIndex: '',
      // categories,
      editableData: null,
      roadmap_id: null,
      defaultColor: '#2b80ad'
    }
  },
  computed: {
    ...mapGetters({
      communicators: "communicator/communicators",
      roadmaps: "roadmap/programmatics",
      project: "project/project",
      categories: "communicator/categories",
    }),
    getDisplayData() {
      let communicators = [];
      if (this.displayMode === 'all') {
        communicators = this.communicators;
      } else if (this.displayMode === 'deleted') {
        communicators = this.communicators.filter(item => item.deleted_at);
      } else {
        communicators = this.communicators.filter(item => item.category && item.category.id === this.displayMode);
      }

      if (this.searchField.text) {
        return communicators.filter((item) => {
          return this.searchField.text
              .toLowerCase()
              .split(" ")
              .every((v) => item.title.toLowerCase().includes(v));
        });
      }

      if(this.roadmap_id){
        return communicators.filter((item) => this.roadmap_id === item.roadmap_id)
      }

      return communicators;
    }
  },
  methods: {
    setDefault(item)
    {
      const defaultData = this.getDisplayData.filter(item=>item.is_default)
      defaultData.forEach(element => {
        const data = {
          id: element.id,
          category: element.category,
          title: element.title,
          is_default: 0,
        }
        axios.post(`communicators/${element.id}/update`, data).then(response => response)
      })

      const data = {
        id: item.id,
        category: item.category,
        title: item.title,
        is_default: 1
      }

      axios.post(`communicators/${item.id}/update`, data).then(response => response)
    },

    outsideSearchInput() {
      this.searchField.placeholder = 'Search';
    },
    toggleCreateModal(value) {
      this.cModal = value;
      this.editableData = null;
    },
    toggleDisplay(mode) {
      this.displayMode = mode;
      this.editableData = null;
    },
    toggleEditModal(value, item) {
      this.eModal = value;
      this.editableData = item;
      this.$refs.communicator_edit.setEditableData(item);
    },
    duplicateCommunicator(item) {
      this.$Progress.start();
      this.$store.dispatch("communicator/duplicateCommunicator", item).then(response => {
        this.$Progress.finish();
      });
    },
    deleteCommunicator(id, index) {
      let payload = {
        id: id,
        index: index,
        project_id: this.$route.params.id
      };
      this.$Progress.start();
      this.$store.dispatch("communicator/deleteCommunicator", payload).then(response => {
        this.$Progress.finish();
      })
    },
    mouseOver(event, index) {
      this.hoveredIndex = index;
    },
    outsideOption() {
      this.hoveredIndex = '';
    },
    setActiveCommunicator(item) {
      this.$Progress.start();
      this.$store.dispatch("communicator/getCommunicator", {roadmap_id: item.id}).then(response => {
        if (response) {
          this.$Progress.finish();
        }
      })
    },
    getContentsLength(item) {
      if(item.scenario && item.scenario.collections) {
        return item.scenario.collections.length;
      }
      return 0;
    }
  }
}
</script>

<style scoped>
/*   @media all and (max-width: 1850px){
    .communicatorCards{
      grid-template-columns: repeat(auto-fit, minmax(370px, 500px));
      gap: 32px;
    }
    .communicatorCards>.scenario-item-box{
      width: 100%;
    }
  } */
</style>
