<template>
    <div class="flex justify-between cursor-pointer text-primary-one opacity-60 mt-8"
        @click="$emit('onClick', item)" :class="{'opacity-100 text-red-500': active }">
        <h2 class="fs-14 font-poppins fw-600">{{ item.title ? item.title : item.name }}</h2>
        <h2 class="fs-14 font-poppins fw-600"> <span v-show="item.count != 'all'"> {{ item.count }} / </span> {{ item.countOf }}</h2>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object
        },
        active: {
            type: Boolean,
            default: false
        }
    }
}
</script>
