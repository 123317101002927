<template>
  <div class="i-left-panel shadow-one">
    <workspace-left-menu />
    <button
        v-if="canCreate"
        @click="toggleCreateModal(true)"
        class="ws-folder-button focus:outline-none hover:bg-secondary-five text-primary-one fs-14 font-poppins fw-600 bg-primary-three shadow-one w-full mt-5 rounded flex items-center justify-center">
        {{ label }}
    </button>
    <div class="ws-folder-box overflow-y-auto scenario-left-box">
      <FilterList
        v-for="(category, cIndex) in localCategories"
            :key="cIndex"
            :item="category"
            :active="currentIndex === cIndex"
            @onClick="toggleDisplay(category, cIndex)"
        />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FilterList from "@/elements/leftSidebar/FilterList.vue";

export default {
    name: "Sidebar",
    props: ["feature", "displayMode", "categories", "label", "items", "canCreate"],
    data() {
        return {
            currentIndex: 0
        }
    },
    computed: {
        localCategories() {
            let data = [`All ${this.feature} Use Cases`, ...this.categories];
            return data.map((item, index) => {
                let key = item && item.id ? item.id : index - 1;
                if(item == `All ${this.feature} Use Cases`) {key = 'all'}
                // if(item == 'Deleted') { key = 'deleted'}
                return {
                    name: item && item.title && this.feature == 'Communicator' ? item.title : item,
                    key,
                    active: index === 0,
                    count: index !== 0 ? this.getCategoryItems(item, index - 1) : 'all',
                    countOf: this.items.length
                };
            });
        }
    },
    methods: {
        toggleCreateModal(value) {
            this.$emit("toggleCreateModal", value);
        },
        toggleDisplay(item, index) {
            this.currentIndex = index;
            this.$emit("toggleDisplay", item.key);
        },
        getCategoryItems(category, index) {
            if(this.feature == 'Communicator') {
              return this.items.filter(item => item.category && item.category.id && item.category.id === category.id).length;
            }
            return this.items.filter(item => item.category_index === index).length;
        }
    },
    components: { FilterList }
}
</script>
