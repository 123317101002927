<template>
    <div class="scenario-item-box rounded shadow-one bg-primary-three i-pl-30 i-pr-30 i-pt-25 i-pb-25 mb-1">

        <div class="flex justify-between items-center i-border-b-1 border-secondary-two w-full pb-1" :style="{borderColor: _isDefault&&_color}">
            <router-link :to="routeObj">
                <h2 class="fs-14 font-poppins text-primary-one fw-600"
                    @click="()=>{canRead(project.cans) && makeActiveCallBack()}">
                    {{ title }}
                </h2>
            </router-link>
            <div class="dropdownToggle ws-project-opt-icon flex justify-center relative cursor-pointer -ml-2">
                <img
                    class="mt-1 opacity-40"
                    src="@/assets/images/icons/workspace/project_opt.svg" alt="icon">

                <div class="z-10 action absolute ws-project-menus px-4 flex flex-col bg-primary-three shadow-two">
                    <router-link v-if="canRead(project.cans)" :to="routeObj">
                        <h6 class="font-poppins fs-14 text-secondary-one opacity-60 pt-2 pb-1 cursor-pointer card-option-menu">Open</h6>
                    </router-link>
                    <a class="font-poppins fs-14 text-secondary-one opacity-60 py-2 cursor-pointer card-option-menu" v-if="canEdit(project.cans)" @click="editCallBack()">Edit</a>
                    <a class="font-poppins fs-14 text-secondary-one opacity-60 py-2 cursor-pointer card-option-menu" v-if="canCreate(project.cans)" @click="duplicateCallBack()">Duplicate</a>
                    <a class="font-poppins fs-14 text-secondary-one opacity-60 py-2 cursor-pointer card-option-menu" v-if="deletable && canDelete(project.cans)" @click="deleteCallBack()">Delete</a>
                </div>
            </div>
        </div>

        <h6 class="fs-12 font-poppins text-secondary-two mt-2">{{ category }}</h6>
        <div class="flex justify-between gap-3 items-center">
            <div>
                <div class="fw-400 fs-14 mt-3 mb-1 whitespace-nowrap" :class="_isDefault&&'fw-700'"> <!-- when active fw-700 -->
                    <SwitchComponent
                        :isRadio="true"
                        :checked="_isDefault"
                        nameAttr="is_default"
                        :callBack="switchCallback"
                        :text="subTitle"
                        :color="_color"
                    />
                </div>
                <span class="fs-12 fw-400 mb-1 block" :style="{color: _color}" v-if="afterSwitch !=''">{{ afterSwitch }}</span>

                <h2 v-for="(item, index) in data" :key="index" class="fs-14 font-poppins text-secondary-one mt-2">
                    {{ Object.keys(item)[0].replaceAll(/_/ig, ' ') }}:
                    <span class="text-secondary-one">
                        {{ item[Object.keys(item)[0]] }}
                    </span>
                </h2>

            </div>
            <!-- {{project}} -->
            <div class="relative">
                <!-- <label class="absolute top-0 right-0 cursor-pointer fileUpload opacity-0">
                    <input @input="(e)=>{onChange(e); _img = previewURL}" hidden type="file" accept="image/x-png,image/jpeg" />
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.94 6 12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11c1.56.1 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3zM8 13h2.55v3h2.9v-3H16l-4-4z"/></svg>
                </label> -->

                <img v-if='_img != ""' :src="_img" alt="demo" class="ws-project-image">
                <img v-else src="@/assets/images/scenario_demo_img.png" alt="demo" class="ws-project-image">
            </div>
        </div>

    </div>
</template>

<script setup>
    import store from '@/store'
    import { computed, ref, watchEffect } from 'vue'
    import SwitchComponent from '@/elements/Switch.vue'
    import usePermission from '@/composable/usePermission.js'
    import { useFile } from '@/composable/useFile.js'

    const { canRead, canCreate, canEdit, canDelete } = usePermission()

    const { onChange, previewURL } = useFile()

    const props = defineProps({
        routeObj: {
            type: Object
        },
        title: {
            type: String
        },
        subTitle: {
            type: String,
            default: 'Active Scenario'
        },
        category: {
            type: String
        },
        afterSwitch: {
            type: String
        },
        image: {
            type: String,
            default: ''
        },
        imagePreview: {
            type: Function,
            default: ()=>{}
        },
        data: {
            type: Array
        },
        editCallBack: {
            type: Function,
            default: ()=>{}
        },
        makeActiveCallBack: {
            type: Function,
            default: ()=>{}
        },
        duplicateCallBack: {
            type: Function,
            default: ()=>{}
        },
        deleteCallBack: {
            type: Function,
            default: ()=>{}
        },
        defaultSetCallBack: {
            type: Function,
            default: ()=>{}
        },
        color: {
            type: String
        },
        isDefault: {
            type: [Number, Boolean],
            default: 0
        },
        deletable: {
          type: Boolean,
          default: true
        }
    })

    const _img       = ref(props.image)
    const _color     = ref()
    const _isDefault = ref()

    watchEffect(()=>{
        _color.value = props.color
        _isDefault.value = props.isDefault
    })

    const switchCallback = (status)=>{
        if(!status){
            _color.value = '#888'
        }
        props.defaultSetCallBack()
    }

    const project = computed(()=>{
        return store.getters['project/project']
    })
</script>

<style scoped>
    .ws-project-image{
        aspect-ratio: 4/2;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
    .dropdownToggle .action {
        display: none;
    }
    .dropdownToggle:hover img{
        opacity: 1 !important;
    }
    .dropdownToggle:hover .action{
        display: flex;
    }
    .scenario-item-box{
        height: auto;
    }
    .scenario-item-box:hover .fileUpload{
        opacity: 1;
    }
</style>
